<template>
  <div class="container">
    <a-card class="login-card">
      <a-form :form="form">
        <a-form-item
          :label-col="formTailLayout.labelCol"
          :wrapper-col="formTailLayout.wrapperCol"
          v-model="user.type"
        >
          <a-input v-model="user.username" placeholder="请输入用户名">
            <a-icon slot="prefix" type="user" style="color: #1890ff" />
          </a-input>
        </a-form-item>
        <a-form-item
          :label-col="formTailLayout.labelCol"
          :wrapper-col="formTailLayout.wrapperCol"
          v-model="user.password"
        >
          <a-input
            v-model="user.password"
            type="password"
            placeholder="请输入验证码"
          >
            <a-icon slot="prefix" type="lock" style="color: #1890ff" />
          </a-input>
        </a-form-item>
        <span @click="onobtain" class="forget">获取验证码</span>
      </a-form>
      <a-form style="margin: 15px 0 0 0">
        <a-form-item
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 12, offset: 6 }"
        >
          <a-button type="primary" @click="check" class="pri-btn">
            提交
          </a-button>
        </a-form-item>
      </a-form>
    </a-card>
  </div>
</template>

<script>
import http from '../../http'
const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 10 },
}
const formTailLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 12, offset: 6 },
}
export default {
  data() {
    return {
      checkNick: true,
      formItemLayout,
      formTailLayout,
      form: this.$form.createForm(this, { name: 'dynamic_rule' }),

      user: {
        mobile: '',
      },
      email: {
        email: '',
      },
    }
  },
  methods: {
    onobtain() {
      if (this.email.mobile == '') {
        this.$message.error('请输入手机号')
      }
    },
    check() {
      this.form.validateFields(err => {
        if (!err) {
          this.forgotrest()
        } else {
          this.$message.error('登录失败')
        }
      })
    },
    async forgotrest() {
      try {
        const res = await http.forgotrest(this.user)
        // console.log(res);
        const { success, msg } = res.data
        if (success) {
          this.$message.success(msg)
          this.$router.push('welcome')
        } else {
          this.$message.error(msg)
        }
      } catch (ex) {
        console.log('error:' + ex)
      }
    },
  },
  mounted() {},
}
</script>
<style scoped>
.login-card {
  width: 500px;
  margin: 300px auto;
}
.container {
  background-image: url('https://gw.alipayobjects.com/zos/rmsportal/TVYTbAXWheQpRcWDaDMu.svg');
  background-repeat: no-repeat;
  background-position: center 110px;
  background-size: 100%;
  overflow: hidden;
}
.pri-btn {
  width: 100%;
}
.forget {
  font-weight: 200;
  position: absolute;
  right: 143px;
  bottom: 123px;
  cursor: pointer;
}
</style>
